<template>
<div>
  <!-- <v-fab-transition>
    <v-btn color="primary" class="stemp-time-runner" depressed @click="$router.push('/stemp')" v-if="isTimerRunning" fab dark small fixed top right>{{time}}</v-btn>
  </v-fab-transition> -->
  <v-row  justify="end" class="mx-2 pr-2 mt-1">
    <v-fab-transition>
      <v-btn width="70" color="primary" class="stemp-time-runner" depressed @click="$router.push('/stemp')" v-if="isTimerRunning" fab dark>{{time}}</v-btn>
    </v-fab-transition>
  </v-row>
  <!-- <v-btn small color="primary" depressed @click="$router.push('/stemp')" v-if="isTimerRunning">
    {{ time }}
  </v-btn> -->
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import TimerMixin from '../mixins/timer'
export default {
  mixins: [TimerMixin],
  data () {
    return {
      time: '00:00:00',
      webWorkerInstance: null,
      isTimerRunning: false
    }
  },
  computed: {
    ...mapGetters(['userDetails'])
  },
  mounted () {
    this.getRunningStemp()
    // let isRunning = window.localStorage.getItem('is_timer_running') || 'false'
    // if (isRunning === 'true') this.getRunningStemp()
  }
}
</script>
<style>
.stemp-time-runner {
  border-radius: 2px;
  padding: 5px 10px;
  height: auto !important;
  width: auto !important;
  top: 5px !important;
  left: 5px !important;
  z-index: 6 !important;
}
</style>
